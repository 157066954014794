<template>
  <v-container>
    <perseu-card title="Cadastro do Perfil Terapeuta">
      <div class="text-center" slot="content">
        <v-form ref="form">
          <v-text-field
            label="Terapeuta *"
            v-model="data.therapistName"
            :rules="[$rules.required]"
          />
          <v-text-field
            label="E-mail *"
            v-model="data.therapistEmail"
            :rules="[$rules.required, $rules.email]"
          />

          <div class="d-flex align-center flex-column">
            <v-hover v-slot="{ hover }">
              <div class="photo--container" @click="openPhotoSelection">
                <v-avatar size="150">
                  <v-img :src="profilePhoto" />
                </v-avatar>
                <v-fade-transition>
                  <div
                    v-if="hover"
                    class="
                      d-flex
                      transition-fast-in-fast-out
                      grey
                      darken-3
                      v-card--reveal
                      display-3
                      white--text
                    "
                  >
                    <div>
                      <v-icon color="white" size="50">add_a_photo</v-icon>
                    </div>
                  </div>
                </v-fade-transition>
              </div>
            </v-hover>
            <p class="mt-3">Foto de perfil (toque para alterar)</p>
          </div>
          <input
            v-show="false"
            ref="fileInput"
            type="file"
            accept="image/.jpg,.jpeg,.png"
            @change="changedPhoto"
          />

          <div class="d-flex justify-center">
            <v-switch
              v-model="data.formedByParmagnetico"
              label="Selo Par Magnético (formado no Instituto Par Magnético)"
            />
          </div>
          <v-textarea
            ref="textBio"
            v-model="data.bio"
            rows="3"
            label="Bio"
            auto-grow
            outline
            clearable
            outlined
            class="mt-5"
            maxlength="500"
            counter
            :rules="[$rules.required]"
          />
        </v-form>
      </div>
      <template slot="actions">
        <v-btn large color="secondary" outlined @click="back"> Cancelar </v-btn>
        <v-btn large color="primary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-container>
</template>

<script>
import { getOne, save } from "@/services/therapists-management-service";
import { awsPutImage, getProfilePreSignedUrl } from "@/services/aws-service";

export default {
  name: "FormManageTerapist",

  data: () => ({
    data: {},
    preview: {
      file: {},
      url: "",
    },
  }),
  created() {
    this.load();
  },
  computed: {
    profilePhoto() {
      return this.preview.url || require("@/assets/no-photo.svg");
    },
  },
  methods: {
    openPhotoSelection() {
      this.$refs.fileInput.click();
    },
    changedPhoto(event) {
      const file = event.target.files[0];
      const fileSizeInKb = file.size / 1000;
      if (fileSizeInKb > 500) {
        this.$errorAlert({
          message: "Imagem muito grande. Máximo permitido: 500kb",
        });
        return;
      }
      this.preview = {
        file,
        url: URL.createObjectURL(file),
      };
    },
    async load() {
      if (this.$route.query.id) {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getOne(this.$route.query.id);
        this.data = data;
        this.preview.url = this.data.photo;
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async back() {
      this.$router.go(-1);
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (this.preview.file.size > 10) {
          await this.uploadPhotoAndGetUrl();
        }
        await save(this.data);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async uploadPhotoAndGetUrl() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data: awsDto } = await getProfilePreSignedUrl();
        await awsPutImage({
          contentType: awsDto.contentType,
          url: awsDto.url,
          key: awsDto.key,
          fileImage: this.preview.file,
        });
        this.data.photo = `${awsDto.resourceUrl}?t=${new Date().getTime()}`;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.photo--container {
  position: relative;
  cursor: pointer;

  width: 250px;
}

.v-card--reveal {
  height: 100%;
  width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
}
</style>
