import api from "./api-service";
import { stringify } from "querystring";

const baseUrl = "therapists-management";

export const getData = () => api().get(`${baseUrl}/data`);

export const find = (filter) => api().get(`${baseUrl}?${stringify(filter)}`);

export const getOne = (id) => api().get(`${baseUrl}/${id}`);

export const save = (data) => api().put(`${baseUrl}`, data);
