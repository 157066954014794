import api from "./api-service";
import axios from "axios";
import querystring from "querystring";

export const getProfilePreSignedUrl = () => {
  return api().get("/aws/profile/pre-signed-url");
};

export const getCertificatePreSignedUrl = (contentType) => {
  return api().get(
    "/aws/pre-signed-url/certificate?" + querystring.stringify({ contentType })
  );
};

export const getConsentTermPreSignedUrl = (contentType) => {
  return api().get(
    "/aws/pre-signed-url/consent-term?" + querystring.stringify({ contentType })
  );
};

export const getBodyPointPreSignedUrl = (contentType) => {
  return api().get(
    "/aws/pre-signed-url/body-point?" + querystring.stringify({ contentType })
  );
};

export const getRandomPreSignedUrl = () => {
  return api().get("aws/random");
};

export const uploadToS3 = ({ image, url, key, contentType }) => {
  return axios.put(url, image, {
    headers: {
      "Content-Type": contentType,
      Key: key,
    },
  });
};

export const awsPutImage = ({ url, key, contentType, fileImage }) =>
  axios.put(url, fileImage, {
    headers: {
      Key: key,
      "Content-Type": contentType,
    },
  });
